* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #f2f5fd;
}
.campaignTable {
  background-color: #fff !important;
  border: 1px solid #a9a7a760;
}

.campaignTable-thead .campaignTable-cell {
  background-color: #fff !important;
  color: #2c2c2c !important;
  font-family: "Poppins" !important;
  border-bottom: 1px solid #a9a7a760 !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 12px !important;
  padding: 10px 16px !important;
}
.campaignTable-tbody .campaignTable-cell {
  color: #000;
  font-family: "Poppins" !important;
  background-color: #fff;
}
.campaignTable-tbody .campaignTable-cell:hover {
  background-color: #fff !important;
  color: #000 !important;
}
.campaignTable .ant-empty-description {
  color: #000;
}
.campaignTable-cell::before {
  background-color: #a9a7a7b2 !important;
}
.campaignTable-tbody .campaignTable-row:hover {
  background-color: #f6f6f6 !important;
  color: #000;
}
.campaignTable-tbody .campaignTable-row:hover .campaignTable-cell {
  background-color: #f6f6f6 !important;
  color: #000;
}
.campaignTable-tbody .campaignTable-cell {
  border-bottom: 0.3px solid #f0f0f011 !important;
}

.logoutPopup-content {
  width: 230px;
  background-color: #fff !important;
  border-radius: 10px;
}
.logoutPopup-inner {
  background-color: #fff !important;
}
.logoutPopup-arrow {
  display: none !important;
}
input[font1="font1"] {
  font-family: "Poppins", sans-serif !important;
}
input[font2="font2"] {
  font-family: "Poppins", sans-serif !important;
}
.logoutPopup-inner-content button {
  width: 100% !important;
  background-color: #00050b;
  color: #7e7e7e;
  border: none;
}

.logoutPopup-inner {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.logout-box {
  color: #7e7e7e;
  font-size: 16px;
  cursor: pointer;
  font-family: "Poppins";
  height: 40px;
  border: none;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 15px;
}
.logout-box p {
  margin: 0;
}
.logout-box:hover {
  background-color: #296eff;
  color: #fff;
  font-size: 16px;
  font-family: "Poppins";
  border: none;
}

.custom-class .ant-message-notice-content {
  background: #0caf60 !important;
  border-radius: 16px !important;
  padding: 16px;
}
.custom-class .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.custom-class-error .ant-message-notice-content {
  background: #e03137 !important;
  border-radius: 16px !important;
  padding: 16px;
}
.custom-class-error .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.delete-modal-content {
  padding: 0 !important;
}

.add-company-content {
  padding: 0 !important;
}

.inner-select3-selector {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}
.inner-select3-selector {
  background: transparent !important;
}
.inner-select3-selection-placeholder {
  color: #5b5b62 !important;
  font-family: "Poppins" !important;
}
.inner-select3-selection-item {
  color: #000 !important;
  font-family: "Poppins" !important;
}
.inner-select3-selector {
  border: none !important;
  background-color: transparent !important;
}
.inner-select2-selection-search {
  display: flex !important;
  align-items: center !important;
}
.inner-select3-selection-search {
  display: flex !important;
  align-items: center !important;
}

.inner-select3-selection-overflow {
  margin-top: -4px !important;
  gap: 6px !important;
}
.inner-select3-selection-overflow-item {
  border: 1px solid #fff !important;
  border-radius: 7px !important;
  /* margin-left: 10px !important; */
}
.inner-select3-selection-overflow-item-suffix {
  border: unset !important;
  border-radius: unset !important;
  margin-left: unset !important;
}
.inner-select2-selection-overflow {
  margin-left: 0px !important;
}
.inner-select3-selection-item-remove {
  color: #000 !important;
}
.time-picker-input {
  width: unset !important;
}
.time-picker-input input {
  color: #fff !important;
}
.time-picker-input input::placeholder {
  font-family: "Poppins";
  color: #5b5b62 !important;
}
.time-picker-range-separator {
  padding: 0 !important;
}

.time-picker-suffix {
  color: #fff !important;
}
.time-picker-clear {
  color: #fff !important;
}

.inner-select3-dropdown-empty {
  display: none !important;
}

.custom-switch {
  width: 50px;
}
.custom-switch-inner {
  border: 1px solid #fff;
}
.campaignTable-content {
  overflow: auto;
}
.campaignTable-content::-webkit-scrollbar {
  width: 0px;
  height: 0px; /* width of the entire scrollbar */
}
.inner-select3-selection-search {
  color: #000 !important;
  z-index: 999 !important;
}
.inner-select3-clear {
  z-index: 9999 !important;
}

.select-location-content {
  padding: 0 !important;
}
.changePasswordModal-content {
  padding: 0 !important;
}

/* Pagination */

button.interaction-pagination-night-item-link {
  color: #21262c !important;
  border-radius: 8px !important;
  border: 1px solid #21262c !important;
}

.interaction-pagination-night .interaction-pagination-night-item-active {
  border: none !important;
  border-color: #000 !important;
  background: transparent !important;
}
li.interaction-pagination-night-item.interaction-pagination-night-item-1.interaction-pagination-night-item-active {
  border: none !important;
  background: transparent !important;
}
.interaction-pagination-night .interaction-pagination-night-item a {
  color: #000 !important;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.interaction-pagination-night .interaction-pagination-night-item-active a {
  background: #296eff !important;
  color: #fff !important;
  border: none !important;
  border-radius: 10px;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.interaction-pagination-night-options-size-changer {
  /* border: 1px solid #296eff !important; */
  border-radius: 12px !important;
}
.interaction-pagination-options-size-changer {
  /* border: 1px solid #b2b8bf !important; */
  border-radius: 12px !important;
}
.interaction-pagination-night-options-size-changer
  .ant-select-selector
  .ant-select-selection-item {
  color: #fff !important;
}
.interaction-pagination-night-options-size-changer .ant-select-arrow {
  color: #fff !important;
}
.ant-select-selection-item {
  display: flex !important;
  gap: 10px !important;
}
.interaction-pagination-options-size-changer {
  height: 32px !important;
}
.interaction-pagination-options-size-changer .ant-select-selector {
  padding: 0px 10px !important;
}
.interaction-pagination-night-options-size-changer {
  height: 32px !important;
}
.interaction-pagination-night-options-size-changer .ant-select-selector {
  padding: 0px 10px !important;
}
.interaction-pagination-night-options-size-changer
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input {
  display: none !important;
}
.interaction-pagination-night-options-size-changer .ant-select-dropdown {
  background: #296eff !important;
  /* border: 1px solid #21262c; */
}
.interaction-pagination-options-size-changer .ant-select-dropdown {
  border: 1px solid #b2b8bf;
}
.interaction-pagination-night-options-size-changer
  .ant-select-dropdown
  .ant-select-item-option-content {
  color: #fff !important;
}
.interaction-pagination-night-options-size-changer
  .ant-select-dropdown
  .ant-select-item-option-selected
  .ant-select-item-option-content {
  color: #000 !important;
}
.interaction-pagination-night-options {
  margin-top: 10px !important;
}
.interaction-pagination-options {
  margin-top: 10px !important;
}
.interaction-pagination .interaction-pagination-item-active {
  font-weight: 600;
  background-color: #f0f0f0;
  border-color: transparent;
  color: #000 !important;
}
.interaction-pagination .interaction-pagination-item-active a {
  color: #000 !important;
}
.interaction-pagination-night
  .interaction-pagination-night-jump-prev
  .interaction-pagination-night-item-container
  .interaction-pagination-night-item-ellipsis,
.interaction-pagination-night
  .interaction-pagination-night-jump-next
  .interaction-pagination-night-item-container
  .interaction-pagination-night-item-ellipsis {
  color: #000 !important;
}
.interaction-pagination-night {
  position: relative;
}
.interaction-pagination-night-options {
  position: absolute;
  right: 0;
  top: -9px;
}
.interaction-pagination-night-options .ant-select-selector {
  background-color: #296eff !important;
  /* border: 1px solid #21262c !important; */
}
.campaignTable-cell .ant-empty-normal {
  min-height: 200px !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.campaignTable-container {
  border-radius: 8px 8px 0 0 !important;
}
.campaignTable-content {
  border-radius: 8px 8px 0 0 !important;
}
.campaignTable-cell .ant-empty-normal {
  width: 91vw !important;
}
.date-picker-input > input {
  color: #000 !important;
  font-family: "Poppins" !important;
  padding-left: 15px !important;
}
.date-picker-input > input::placeholder {
  color: #4c4a4c !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.date-picker-suffix {
  color: #4c4a4c !important;
  margin-right: 15px;
}
.date-picker-clear {
  color: #4c4a4c !important;
  right: 15px !important;
}
.chat-drawer-content-wrapper {
  position: relative !important;
  height: 100% !important;
}
.code-modal-content {
  padding: 0 !important;
}
.code-modal-close-x {
  color: #fff !important;
  margin-bottom: 12px !important;
}
.inner-select3-item-option-active {
  background-color: #c7bdbdbd !important;
}
.inner-select3-item-option-selected {
  background-color: #296eff !important;
  color: #fff !important;
}
.inner-select3-item-option-state > .anticon-check {
  color: #fff !important;
}
.inner-select3-item-option-content {
  font-family: "Poppins" !important;
}
/* .campaignTable-cell-row-hover {
  background: #f9832967 !important;
} */
.tooltip-box-arrow::after {
  background-color: #cecece !important;
}
.tooltip-box-inner {
  background-color: #cecece !important;
  color: #000 !important;
  /* border: 1px solid #fff !important; */
  font-family: "Poppins" sans-serif !important;
}

.anticon-close {
  color: #000 !important;
}
.custom-spin span {
  color: rgb(41, 110, 255) !important;
}
